#root div.midi-column {
    max-width: 38rem;
    min-height: 70vh;
}

.sv_qstn_error_top {
    color: red;
    padding-left: 5px;
}

.sv_main .sv_q_num {
    display: none;
}

.sv_body > div > div:nth-child(2) {
    margin-top: 3.25rem;
}

.sv_q_text_root {
    box-sizing: border-box;
    border-radius: 0.3rem;
    font-family: inherit;
    transition: border-color 0.3s ease 0s;
    width: 100%;
    max-width: 24rem;
    color: rgb(0, 51, 102);
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(204, 204, 204);
    appearance: none;
    font-size: 20px;
    padding: 20px;
}

.sv_q_title {
    margin-bottom: 10px;
}